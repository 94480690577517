import React from "react";
import {StaticQuery, graphql} from "gatsby";

import StateContainer, { StateLocation } from "../components/puntos_de_venta";

import puntos from "../styles/puntos.module.styl"

const QueryPuntos = graphql`
  query QueryPuntos {
    allPuntosDeVentaJson(sort: {order: DESC, fields: main}) {
      edges {
        node {
          address
          location {
            country
            state
          }
          main
          name
          website
        }
      }
    }
  }
`;

export default class PuntosDeVentaPeru extends React.Component {
  render () {

    return (
      <React.Fragment>
        <h3 className={puntos.countryTitle}>Perú</h3>
        <StaticQuery
          query={QueryPuntos}
          render={puntos => {
            const {allPuntosDeVentaJson} = puntos;
            const locationList = [];
            ["Lima"].forEach(state => {
              const locationListCDMX = allPuntosDeVentaJson.edges.filter(node => {
                const location = node.node;
                return location.location.state === state;
              }).map(node => {
                const location = node.node;
                const keyName = location.name;
                return (<StateLocation keyName={keyName} location={location} />);
              });
              locationList.push(<StateContainer state={state} locationList={locationListCDMX}/>);
            });
            return locationList;
          }}
        />
      </React.Fragment>
    )
  }
}

