import React from "react";

import {ExternalLinkIcon} from "./fa";

import puntos from "../styles/puntos.module.styl";

export default class StateContainer extends React.Component {
  render() {
    const {locationList, state} = this.props;
    return (
      <div className={puntos.stateContainer}>
        <div className={puntos.header}>
          <h4>{state}</h4>
        </div>
        <div className={puntos.list}>
          {locationList}
        </div>
      </div>
    );
  }
}

export class StateLocation extends React.Component {
  render() {
    const {keyName, location} = this.props;
    return (
      <div className={puntos.location} key={keyName}>
        {
          location.website ?
            <a className={puntos.name} href={location.website}
               target={"_blank"} rel="noopener noreferrer">
              <ExternalLinkIcon /> {location.name}
            </a> :
            <p className={puntos.name}>{location.name}</p>
        }
        {
          location.address ?
            <p>{location.address}</p> : ""
        }
      </div>
    );
  }
}

