import React from "react";
import {StaticQuery, graphql} from "gatsby";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";
import StateContainer, { StateLocation } from "../components/puntos_de_venta";
import PuntosDeVentaPeru from "../components/puntos_de_venta_peru";

import shared from "../styles/shared.module.styl";
import puntos from "../styles/puntos.module.styl"
import { Helmety } from "../components/Head";

const QueryPuntosDeVenta = graphql`
  query QueryCDMX {
    allPuntosDeVentaJson(sort: {order: DESC, fields: main}) {
      edges {
        node {
          address
          location {
            country
            state
          }
          main
          name
          website
        }
      }
    }
  }
`;

export default class PuntosDeVenta extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/puntos_de_venta/"
          title="Puntos de venta - Dharma Books"
          content="Libro objeto a precio discreto."
          imgURL="https://www.dharmabooks.com.mx/static_resources/logos/griso_sl.png"
        />
        <div className={shared.container}>
          <SectionHeader header="Puntos de venta" />
          <h3 className={puntos.countryTitle}>México</h3>
          <StaticQuery
            query={QueryPuntosDeVenta}
            render={data => {
              const {allPuntosDeVentaJson} = data;
              const locationList = [];
              ["Ciudad de México", "Jalisco", "Puebla", "Oaxaca", "Querétaro", "Morelia"].sort().forEach(state => {
                const locationListCDMX = allPuntosDeVentaJson.edges.filter(node => {
                  const location = node.node;
                  return location.location.state === state;
                }).map(node => {
                  const location = node.node;
                  const keyName = location.name;
                  return (<StateLocation keyName={keyName} location={location} />);
                });
                locationList.push(<StateContainer state={state} locationList={locationListCDMX}/>);
              });
              return locationList;
            }}
          />
          <PuntosDeVentaPeru />
        </div>
      </MainLayout>
    )
  }
}

